<template>
  <div>
    <Table
      @edit_item="edit_item($event)"
      ref="table"
      @data_loaded="data_loaded = true"
      :data_loaded="data_loaded"
    />

    <v-btn
      elevation="8"
      class="mt-5"
      v-if="!form && data_loaded && $store.getters.permission('settings edit')"
      @click="form = !form"
      fab
      small
    >
      <v-icon>add</v-icon>
    </v-btn>

    <v-snackbar left v-model="snackbar">
      <p class="white--text">ההגדרות עודכנו בהצלחה</p>
      <template v-slot:action>
        <v-icon color="green" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>

    <Form
      @update_table="update_table()"
      @close_form="close_form()"
      :item="item"
      v-if="form && $store.getters.permission('settings edit')"
    />
  </div>
</template>

<script>
import Table from "@/components/settings/notifications/Table";
import Form from "@/components/settings/notifications/Form";
export default {
  name: "notifications",
  data() {
    return {
      form: false,
      snackbar:false,
      data_loaded: false,
      item: {
        id: null,
        send_type: "sms",
        noti_type: "orders",
        order_status: [],
        send_to: [],
        msg: "",
        status: true,
      },
    };
  },
  components: {
    Table,
    Form,
  },
  methods: {
    update_table() {
      this.$refs.table.get_data();
      this.snackbar = true;
    },
    edit_item(item) {
      this.item = Object.assign({}, item);
      this.$nextTick(() => {
        this.form = true;
      });
    },
    close_form() {
      this.item = Object.assign(
        {},
        {
          id: null,
          send_type: "sms",
          noti_type: "orders",
          order_status: [],
          send_to: [],
          msg: "",
          status: true,
        }
      );

      this.form = false;
    },
  },
};
</script>
