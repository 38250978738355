<template>
  <div>
    <v-skeleton-loader v-if="!data_loaded" type="table"></v-skeleton-loader>
    <v-card v-if="data_loaded">
      <v-card-title>
        <div class="d-flex align-center">
          <v-icon x-large>notifications</v-icon>
          <h1 class="ms-3">עדכונים</h1>
        </div>
        <v-spacer></v-spacer>
        <v-btn text exact to="/settings" color="primary">
          <v-icon>keyboard_backspace</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        class="text-center"
        :headers="headers"
        :items="items"
        mobile-breakpoint="0"
        disable-pagination
        hide-default-footer
      >
        <template v-slot:item.status="{ item }">
          <v-chip
            x-small
            class="px-2"
            :color="item.status ? 'success black--text' : 'error'"
          >
            {{ item.status ? "פעיל" : "לא פעיל" }}
          </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn @click="$emit('edit_item', item)" v-if="$store.getters.permission('settings edit')" icon small>
            <v-icon small>edit</v-icon>
          </v-btn>
          <v-btn
            v-if="$store.getters.permission('settings edit')"
            icon
            small
            @click="delete_row(item.id)"
          >
            <v-icon small>delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-pagination
      v-if="data_loaded"
      class="mt-5"
      @input="get_data()"
      v-model="page"
      total-visible="7"
      :length="total"
      circle
    ></v-pagination>

    <DeleteDialog
      @delete_done="get_data()"
      @close_delete="delete_obj.delete_prop = false"
      :delete_obj="delete_obj"
    ></DeleteDialog>
  </div>
</template>

<script>
import DeleteDialog from "@/components/public/delete";
export default {
  name: "Table",
  props: ["data_loaded"],
  data() {
    return {
      delete_obj: {
        delete_prop: false,
        delete_url: "settings/notifications",
        title: "מחיקת עדכונים",
        id: null,
      },
      items: [],
      headers: [
        {
          value: "send_type",
          text: "איך לעדכן?",
          align: "center",
        },
        {
          value: "noti_type",
          text: "איפה לעדכן",
          align: "center",
        },
        {
          value: "send_to_count",
          text: "למי לשלח",
          align: "center",
        },
        {
          value: "order_status_count",
          text: "באיזה סטטוסים",
          align: "center",
        },
        {
          value: "status",
          text: "סטטוס",
          align: "center",
        },
        {
          value: "actions",
          text: "פעולות",
          align: "center",
        },
      ],
      total: 0,
      page: 1,
    };
  },
  components: {
    DeleteDialog,
  },
  methods: {
    get_data() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `settings/notifications?page=${this.page}`,
            method: "get",
          },
        })
        .then((res) => {
          const data = res.data.data;
          this.items = data.data;
          this.page = data.meta.current_page;
          this.total = data.meta.last_page;
          this.$emit("data_loaded");

          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    delete_row(id) {
      this.delete_obj.id = id;
      this.delete_obj.delete_prop = true;
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>
