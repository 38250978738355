<template>
  <v-card class="mt-10 mb-10">
    <v-card-title> עדכון חדש</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="form">
        <v-row class="basic mt-2">
          <v-col cols="6" md="3" class="py-0">
            <v-select
              outlined
              dense
              :items="['sms']"
              :rules="[(v) => !!v || 'חובה']"
              v-model="item.send_type"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                איך לעדכן?
              </template>
            </v-select>
          </v-col>
          <v-col cols="6" md="3" class="py-0">
            <v-select
              outlined
              dense
              :items="noti_types"
              item-text="text"
              item-value="value"
              v-model="item.noti_type"
              :rules="[(v) => !!v || 'חובה']"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                איפה לעדכן
              </template>
            </v-select>
          </v-col>
          <v-col cols="6" md="3" class="py-0">
            <v-select
              outlined
              dense
              :items="order_status"
              item-text="text"
              item-value="value"
              multiple
              v-model="item.order_status"
              :rules="[(v) => !!item.order_status.length || 'حقل مطلوب']"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                באיזה סטטוסים
              </template>
            </v-select>
          </v-col>
          <v-col cols="6" md="3" class="py-0">
            <v-switch label="" v-model="item.status"></v-switch>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-combobox
              v-model="item.send_to"
              :items="[]"
              :rules="[(v) => !!item.send_to.length || 'חובה']"
              :search-input.sync="search"
              hide-selected
              multiple
              persistent-hint
              small-chips
              dense
              outlined
              prepend-icon="phone"
              @input="combobox_change($event)"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                למי לשלח
              </template>
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <strong>{{ search }}</strong
                      >". Press <kbd>enter</kbd> to create a new one
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip v-bind="attrs" :input-value="selected" label small>
                  <span class="pr-2">
                    {{ item }}
                  </span>
                  <v-icon small @click="remove(item)"> $delete </v-icon>
                </v-chip>
              </template>
            </v-combobox>
          </v-col>

          <v-col cols="12" class="py-0">
            <v-alert color="info white--text" dense>
              פרמטרים להוספה תוך ההודעה
            </v-alert>

            <v-chip-group active-class="primary--text" column>
              <v-chip
                @click="add_key(key.value)"
                v-for="(key, i) in keys"
                :key="i"
              >
                {{ key.text }}
              </v-chip>
            </v-chip-group>
            <v-textarea
              :rules="[(v) => !!v || 'חובה']"
              outlined
              v-model="item.msg"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                תוכן ההודעה
              </template>
            </v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-actions class="justify-end px-4">
      <div class="d-flex">
        <v-btn
          @click="close_form()"
          x-large
          depressed
          height="40"
          color="black--text"
        >
          <v-icon>arrow_forward</v-icon>
        </v-btn>
        <v-btn
          @click="save()"
          :loading="save_loader"
          :disabled="save_loader"
          x-large
          depressed
          height="40"
          color="black white--text ms-3"
          >שמירה</v-btn
        >
      </div>
    </v-card-actions>

    

    
  </v-card>
</template>

<script>
export default {
  name: "Form",
  props:['item'],
  data() {
    return {
      save_loader: false,
      noti_types: [
        {
          value: "orders",
          text: "orders",
        },
      ],
      order_status: [
        {
          value: "pending",
          text: "בהמתנה",
        },
        {
          value: "new",
          text: "חדש",
        },
        {
          value: "preparation",
          text: "בהכנה",
        },
        {
          value: "ready",
          text: "מוכן",
        },
        {
          value: "in_progress_delivery",
          text: "בדרך",
        },
        {
          value: "delivery_arrived",
          text: "הגיע השליח",
        },
        {
          value: "done",
          text: "טופל",
        },
        {
          value: "canceled",
          text: "מבוטלת",
        },
      ],

      keys: [
        {
          value: "{order_num}",
          text: "מספר הזמנה",
        },
        {
          value: "{total_order}",
          text: "اجمالي الطلب",
        },
        {
          value: "{total_products}",
          text: "סה״כ מוצרים",
        },
        {
          value: "{customer_name}",
          text: "שם לקוח",
        },
        {
          value: "{customer_phone}",
          text: "מספר טלפון",
        },
        // {
        //   value: "{customer_email}",
        //   text: "رقم هاتف العميل",
        // },
        {
          value: "{customer_address}",
          text: "כתובת לקוח",
        },
      ],

      search: null,

    };
  },

  methods: {
    close_form() {
      if (this.save_loader) {
        this.$emit("update_table");
        this.save_loader = false;
      }
      this.$emit("close_form");

    },
    save() {
      if (this.$refs.form.validate() && !this.save_loader) {
         this.save_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: this.item.id ? `settings/notifications/${this.item.id}` : 'settings/notifications' ,
              method: this.item.id  ?  'put' : "POST",
            },
            data: this.item,
          })
          .then((res) => {
            this.close_form();
            console.log(res);
          })
          .catch((err) => {
            this.save_loader = false;
          });
      }
    },
    remove(item) {
      let index = this.item.send_to.findIndex((e) => e == item);
      this.item.send_to.splice(index, 1);
    },
    add_key(key) {
      this.item.msg = `${this.item.msg} ${key}`;
    },
    combobox_change(ev) {
      ev.forEach((item, x) => {
        if (isNaN(item)) {
          this.item.send_to.splice(x, 1);
          console.log(ev);
        }
      });
    },
  },
};
</script>
